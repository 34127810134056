import React from 'react'
import './pipe.css'

function HorizontalBlue() {
    return (
        <>
            <div className='wrapper-horizontal-pipe'>
                <div className='horizontal-blue'></div>
            </div>
        </>
    )
}

export default HorizontalBlue