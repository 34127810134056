import React from 'react'
import './blackbox.css'

function Blackboxstop() {
    return (
        <>
            <div className='black-box-stop'></div>
        </>
    )
}

export default Blackboxstop