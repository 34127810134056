import React from 'react'
import './pipe.css'

function VerticalBlue() {
    return (
        <>
            <div className='wrapper-vertical-pipe'>
                <div className='vertical-blue'></div>
            </div>
        </>
    )
}

export default VerticalBlue