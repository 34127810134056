import React from 'react'

function Bowbottomleft() {
    return (
        <>
            <div className='wrapper-bow'>
                <div className='bow-left-bottom'></div>
            </div>
        </>

    )
}

export default Bowbottomleft