import React from 'react'

const Loader = () => {
    return (
        <>
            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
            Loading...
        </>
    )
}

export default Loader