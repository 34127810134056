import React from 'react'
import './pipe.css'

function Bowtopleft() {
    return (
        <>
            <div className='wrapper-bow'>
                <div className='bow-left-top'></div>
            </div>
        </>

    )
}

export default Bowtopleft