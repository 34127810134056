import React from 'react'
import './blackbox.css'

function Blackbox() {
    return (
        <>
            <div className='black-box'></div>
        </>
    )
}

export default Blackbox