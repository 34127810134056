import React from 'react'
import './pipe.css'

function HorizontalGreen() {
    return (
        <>
            <div className='wrapper-horizontal-pipe'>
                <div className='horizontal-green'></div>
            </div>
        </>
    )
}

export default HorizontalGreen